import React from "react"

function Logo() {
  return (
    <div
      className="inline text-white text-2xl font-medium bg-black rounded p-2 mr-3 bg-indigo-600"
    >
      gb
    </div>
  )
}

export default Logo
